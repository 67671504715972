#Banner0_0.l4sn6lhhhxs-editor_css {
  background-image: url(https://www.wechat-connector.com/static/image/bg-shanghai.jpg);
}
#Banner0_0 .l4su9uk1zxs-editor_css {
  top: 60%;
}
#Banner0_0 .banner0-text-wrapper > .l4sn70lz54s-editor_css {
  width: 500px;
  left: -20px;
}
#Content1_0 .ant-row > .ant-col > .l4t4hfo45f-editor_css {
  width: 350px;
}
